import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import "./style.scss";
import CurrentDayView from "../current_day_view/CurrentDayView";
import CapacityDetailSection from "../capacity_detail_section/CapacityDetailSection";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    key: index,
  };
}

export const DayViewWrapper = ({ dailyData, labels }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {dailyData && labels && (
        <div className="day-view-wrapper">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="day view tabs"
              variant="fullWidth"
            >
              {labels.map((label, index) => {
                const dateArray = label.split("-");

                const dateWrapper = dateArray.map((date, index) =>
                    <span key={index}>{date} </span>
                );

                return <Tab label={<div className="date-wrapper">{dateWrapper}</div>} {...a11yProps(index)} />;
              })}
            </Tabs>
          </Box>
          {dailyData.map((dayData, index) => {
            return (
              <TabPanel value={value} index={index} key={index}>
                <CurrentDayView dayData={dayData} />
                <CapacityDetailSection staffBands={dayData.staffBands} />
              </TabPanel>
            );
          })}
        </div>
      )}
    </>
  );
};

export default DayViewWrapper;
