import React, { useState } from "react";
import { Container } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { format, add } from "date-fns";

import { getRequest } from "../services/base_requests";

import WeeklyPlannerFilterForm from "../components/weekly_planner/weekly_planner_filter_form/WeeklyPlannerFilterForm";

import WeekViewSection from "../components/weekly_planner/week_view_section/WeekViewSection";

const dateNow = new Date();
const startDate = dateNow;
const endDate = add(dateNow, { days: 6 });

export const WeeklyPlannerPage = ({ groups }) => {
  const [loadingChartData, setLoadingChartData] = useState(false);
  const [weeklyPlannerChartData, setWeeklyPlannerChartData] = useState(undefined);
  const [chartData, setChartData] = useState(null);
  const [dailyData, setDailyData] = useState([]);
  const [dailyDataLoading, setDailyDataLoading] = useState(false);
  const [dayLabels, setDayLabels] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [dateFrom, setDateFrom] = useState(startDate);
  const [dateTo, setDateTo] = useState(endDate);
  const [isOpenFrom, setIsOpenFrom] = useState(false);
  const [isOpenTo, setIsOpenTo] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const groupsForSelect = [
    { value: "", label: "All groups" }, ...groups.map((group) => { return { value: group.id, label: group.name }; }) 
  ];

  const updateDateFrom = (value) => {
    setDateFrom(value);
    setDateTo(add(value, { days: 6 }));
  };

  const selectedGroupsValue = selectedGroups.map(group => group.value);

  const getChartData = () => {
    setLoadingChartData(true);

    Promise.all([
      getRequest({
        url: "/api/dashboard/v1/weekly-planner/chart",
        params: {
          dateFrom: format(dateFrom, "yyyy-MM-dd"),
          dateTo: format(dateTo, "yyyy-MM-dd"),
          groups: selectedGroupsValue.join(),
        },
      }),
    ]).then(async (responses) => {
      setLoadingChartData(false);
      const weeklyPlannerChartResponse = responses[0];

      const weeklyPlannerChartData = await weeklyPlannerChartResponse.json();
      setWeeklyPlannerChartData(weeklyPlannerChartData)

      const labels = weeklyPlannerChartData.chartData.labels.map(
        (label, index) => {
          return `${label}-${weeklyPlannerChartData.chartData.dates[index].toLowerCase()}`;
        }
      );
      setChartData(weeklyPlannerChartData.chartData);
      setDayLabels(labels);
    }).catch((ex) => {});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loadingChartData) return;

    setDailyData(null);
    getChartData();
  };

  const handleDailyDataSubmit = () => {
    setDailyDataLoading(true);

    Promise.all([
      getRequest({
        url: "/api/dashboard/v1/weekly-planner/daily",
        params: {
          dateFrom: format(dateFrom, "yyyy-MM-dd"),
          dateTo: format(dateTo, "yyyy-MM-dd"),
          groups: selectedGroupsValue.join(),
        },
      }),
    ]).then(async (responses) => {
      setDailyDataLoading(false);

      const weeklyPlannerDailyResponse = responses[0];
      const weeklyPlannerDailyData = await weeklyPlannerDailyResponse.json();
      setDailyData(weeklyPlannerDailyData.dailyData);
    }).catch((ex) => {});
  };

  return (
    <div className="page-container">
      <div className="page-title-container">
        <Container maxWidth="xl">
          <h1>Weekly Planner</h1>
        </Container>
      </div>

      <Container maxWidth="xl">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loadingChartData && !chartData}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div>
          <WeeklyPlannerFilterForm
            isOpenFrom={isOpenFrom}
            setIsOpenFrom={setIsOpenFrom}
            isOpenTo={isOpenTo}
            setIsOpenTo={setIsOpenTo}
            datePickerOpenTo="day"
            datePickerViews={["day", "month", "year"]}
            handleSubmit={handleSubmit}
            hasPeriodHelpers={false}
            dateFrom={dateFrom}
            dateTo={dateTo}
            maxStartDate={startDate}
            calculatesEndDate={true}
            groupsForSelect={groupsForSelect}
            loadingChartData={loadingChartData}
            setDateFrom={updateDateFrom}
            setDateTo={setDateTo}
            setSelectedGroups={setSelectedGroups}
            disableBtn={disableBtn}
            setDisableBtn={setDisableBtn}
          />
          { weeklyPlannerChartData && (
            <>
              <WeekViewSection
                loadingChartData={loadingChartData}
                chartData={chartData}
                labels={dayLabels}
                dailyData={dailyData}
                handleDailyDataSubmit={handleDailyDataSubmit}
                dailyDataLoading={dailyDataLoading}
              />
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default WeeklyPlannerPage;
