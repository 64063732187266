import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import SectionCard from "../../demand_capacity/section_card/SectionCard";

import WeekViewBarChart from "../week_view_bar_chart/WeekViewBarChart";
import DayViewWrapper from "../day_view_wrapper/DayViewWrapper";

export const WeekViewSection = ({ loadingChartData, chartData, labels, dailyData, handleDailyDataSubmit, dailyDataLoading }) => {
  return (
    <>
      <SectionCard title="Week View" loadingData={loadingChartData}>
        {chartData && <WeekViewBarChart chartData={chartData} labels={labels} />}
        <LoadingButton onClick={handleDailyDataSubmit} loading={dailyDataLoading} variant="contained" className="weekly_planner_daily_data_btn">
          {dailyDataLoading ? 'Loading...' : 'Load Daily Breakdown'}
        </LoadingButton>
      </SectionCard>
      <DayViewWrapper dailyData={dailyData} labels={labels} />
    </>
  );
};

export default WeekViewSection;
